<template>
  <div style="background-color: rgba(150,147,143,0.73)!important;">
    <section
        v-if="Object.keys(pricing).length"
        id="pricing-plan"
    >
      <!-- title text and switch button -->
      <div  class="text-center">
        <h1 style="color: white!important;" class="">
          {{ $t('pricingplans') }}
        </h1>
        <h5  class="mb-2 pb-75" style="font-size: 17px;color: white">
          {{ $t('bestapts') }}
        </h5>
        <div style="color: white!important;" class="d-flex align-items-center justify-content-center mb-5 pb-50">
          <h5 style="color: white!important;" class="mr-1 mb-0">
            {{ $t('mind. 3 Monate') }}

          </h5>
          <b-form-checkbox
              id="priceSwitch"
              v-model="status"
              name="price-switch"
              value="annually"
              unchecked-value="monthly"
              switch
              @input="tooglePlan"
          />
          <h5 style="color: white!important;" class="ml-50 mb-0">
            {{ $t('mind. 1 Jahr') }}

          </h5>
        </div>
      </div>
      <!--/ title text and switch button -->

      <!-- pricing plan cards -->
      <b-row class="pricing-card equal-height-row" >
        <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
          <b-row class="equal-height-row">
            <b-col  md="4">
              <b-card align="center">
                <!-- img -->
                <b-img
                    v-if="pricing.basicPlan.img"
                    :src="pricing.basicPlan.img"
                    class="mt-5"
                    style="height: 150px;width: 150px;margin-bottom: 35px"
                    alt="basic svg img"
                />
                <!--/ img -->
                <h3>{{ pricing.basicPlan.title }}</h3>
                <b-card-text>
                  {{ pricing.basicPlan.subtitle }}
                </b-card-text>

                <!-- annual plan -->
                <div style="font-size: 20px" class="annual-plan">
                  <div class="plan-price mt-2">
                  <span  class="pricing-basic-value font-weight-bolder text-primary">{{
                      monthlyPlanShow ? pricing.basicPlan.monthlyPrice : pricing.basicPlan.yearlyPlan.perMonth
                    }}% </span><span style="font-size: 15px;font-weight: bold;color: darkgray">/ (Objekt)</span>
                  </div>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <div style="min-height: 1103px">
                  <b-list-group class=" text-left">
                    <b-list-group-item v-for="(data,index) in pricing.basicPlan.planBenefits" :key="index">
                      <i class="fas fa-check-circle"></i>  {{ data }}
                    </b-list-group-item>
                  </b-list-group>
                </div>

                <!-- buttons -->
                <b-button
                    @click="sendEmail"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="primary"
                >
                  {{ $t('Change plan') }}

                </b-button>
              </b-card>
            </b-col>
            <b-col md="4">
              <b-card class="popular" align="center">
                <div v-show="pricing.standardPlan.popular" class="pricing-badge text-right">
                  <b-badge variant="light-primary" pill>Popular</b-badge>
                </div>
                <!-- img -->
                <b-img v-if="pricing.standardPlan.img" :src="pricing.standardPlan.img" class=""
                       style="height: 220px;width: 220px" alt="svg img"/>
                <!--/ img -->
                <h3>{{ pricing.standardPlan.title }}</h3>
                <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

                <!-- annual plan -->
                <div style="font-size: 20px" class="annual-plan">
                  <div class="plan-price mt-2">
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{
                      monthlyPlanShow ? pricing.standardPlan.monthlyPrice : pricing.standardPlan.yearlyPlan.perMonth
                    }}% </span><span style="font-size: 15px;font-weight: bold;color: darkgray">/ (Objekt)</span>
                  </div>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <div style="min-height: 600px">
                  <b-list-group style="" class="text-left">
                    <b-list-group-item
                        v-for="(data,index) in pricing.standardPlan.planBenefits"
                        :key="index"
                    ><i class="fas fa-check-circle"></i>
                      {{ data }}
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <!--/ plan benefit -->

                <!-- buttons -->
                <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          block class="mt-2 text-uppercase" variant="success">
                  {{ $t('Your current plan') }}
                </b-button>

              </b-card>
            </b-col>
            <b-col md="4">
              <b-card
                  align="center"
              >
                <!-- img -->
                <b-img
                    v-if="pricing.enterprisePlan.img"
                    :src="pricing.enterprisePlan.img"
                    class="mb-2 mt-1"
                    style="height: 200px;width: 220px"
                    alt="enterprise svg img"
                />
                <!--/ img -->
                <h3>{{ pricing.enterprisePlan.title }}</h3>
                <b-card-text>{{ pricing.enterprisePlan.subtitle }}</b-card-text>

                <!-- annual plan -->
                <div style="font-size: 20px" class="annual-plan">
                  <div class="plan-price mt-2">
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{
                      monthlyPlanShow ? pricing.enterprisePlan.monthlyPrice : pricing.enterprisePlan.yearlyPlan.perMonth
                    }}</span>
                  </div>

                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <div style="min-height: 1108px">

                <b-list-group
                    v-for="(data,index) in pricing.enterprisePlan.planBenefits"
                    :key="index"
                    class=" text-left"
                >
                  <b-list-group-item>
                    <i class="fas fa-check-circle"></i>
                    {{ data }}
                  </b-list-group-item>
                </b-list-group>
                </div>
                <!--/ plan benefit -->

                <!-- buttons -->
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    block
                    class="mt-2"
                    variant="primary"
                    @click="sendEmail"
                >
                  {{ $t('Upgrade') }}
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!--/ pricing plan cards -->

    </section>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

export default {
  components: {},
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {
        basicPlan: {
          title: 'Basic Host',
          img: require('@/assets/images/illustration/price2.png'),
          subtitle: '',
          monthlyPrice: 8,
          yearlyPlan: {
            perMonth: 6,
            totalAnual: 1000,
          },
          planBenefits: [
            'Channel Manager (Hostaway)24/7',
            'Chat und Telefonsupport',
            'Elektrisches Schloss ',
            'Buchhaltung',
            'Terminmanagement',
            'Inseratsbearbeitung',
            'Bewertungsmanagement',
            'Chatbot',
            'Zinshausmodul',
            'Dynamische Preise',
          ],
          popular: false,
        },
        standardPlan: {
          title: 'Premium Host',
          img: require('@/assets/images/illustration/price3.png'),
          subtitle: '',
          monthlyPrice: '15-23',
          yearlyPlan: {
            perMonth: '13-20',
            totalAnual: 3100,
          },
          planBenefits: [
            'Channel Manager (Hostaway)24/7',
            'Chat und Telefonsupport',
            'Elektrisches Schloss ',
            'Buchhaltung',
            'Terminmanagement',
            'Inseratsbearbeitung',
            'Bewertungsmanagement',
            'Chatbot',
            'Zinshausmodul',
            'Dynamische Preise',
            'Design und Einrichtung',
            'Wohnungsressourcen',
            'Kontrolle (Handtücher, Bettwäsche, Internet, etc.)',
            'Bereitschaftsdienst (Elektriker, Schlosser, Installateur, Reinigungskraft)',
            'Rechtliche Vertretung',
            'Versicherungsschutz',
            'Gewerbeschirm',
            'Reinigungsmanagement',
            'Langzeitbuchungen (Inkl. Inserate auf Willhaben & Immoscout24)',
            'Überbuchungsmanagement',
            'Conversions von Direktbuchungen inkl. Payment Links von Treasure Home',
          ],
          popular: true,
        },
        enterprisePlan: {
          title: 'Fixe Vermietung',
          img: require('@/assets/images/illustration/price1.png'),
          subtitle: '',
          monthlyPrice: 'Auf Anfrage',
          yearlyPlan: {
            perMonth: 'Auf Anfrage',
            totalAnual: 960,
          },
          planBenefits: [
            'Fixe monatliche Mieteinnahmen',
            'Wohnung wird auf den neuesten Standard eingerichtet',
            'Flexibilität in der Vermietung',
            'Treasure Home vs. regulärer Mieter',
            'Keine Mietzinsrückstände',
            'Keine Mietzinsanfechtungen',
            'Keine Schlichtungsstellen',
          ],
          popular: false,
        },
        qandA: [
          {
            question: 'Does my subscription automatically renew?',
            ans:
                'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
          },
          {
            question: 'Can I store the item on an intranet so everyone has access?',
            ans:
                'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
          },
          {
            question: 'Am I allowed to modify the item that I purchased?',
            ans:
                'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
          },
        ],
      },

    }
  },
  created() {
    /*
    this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
*/
  },
  computed: {},
  methods: {
    sendEmail() {
      // Compose the email link with a subject and body
      const subject = 'Subject of the email';
      const body = 'Content of the email goes here.';
      const emailAddress = 'office@treasurehome.at';

      // Create the mailto link
      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      // Open the default email client with the mailto link
      window.location.href = mailtoLink;
    },
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">

.b-card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-family: 'cursive', sans-serif!important;
  transition: transform 0.3s ease; /* Add transition for smooth effect */
}

.b-card:hover {
  transform: scale(1.05); /* Apply scale transform on hover */
}

.list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 0px solid rgba(34, 41, 47, 0.125) !important;
}
/*@import 'src/pages/page-pricing.scss';*/
</style>
